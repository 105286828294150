var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "product" }, [
    _c("div", { staticClass: "product__photo" }, [
      _c("img", {
        attrs: { src: _vm.product.img },
        on: {
          click: function ($event) {
            return _vm.openModal(_vm.product.modal_img)
          },
        },
      }),
      _vm._v(" "),
      _vm.product.customer_user
        ? _c("span", {
            staticClass: "product__favorite icon-favorite",
            class: { active: _vm.isFavorite },
            on: {
              click: function ($event) {
                return _vm.updateFavoriteStatus(_vm.product.id)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.product.product_of_the_day
        ? _c("span", { staticClass: "product__text-label" }, [
            _vm._v("Товар дня"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.product.discount
        ? _c(
            "div",
            {
              staticClass: "product__discount",
              style: "background-color:" + _vm.product.color,
            },
            [_vm._v("-" + _vm._s(_vm.product.discountValue))]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "product__description" }, [
      _c("div", { staticClass: "product__code-line" }, [
        _c("span", { staticClass: "product__sku" }, [
          _vm._v(_vm._s(_vm.product.sku)),
        ]),
        _vm._v(" "),
        _c(
          "span",
          {
            class:
              "product__stock-status stock-status ss-" +
              _vm.product.stock_status,
          },
          [
            _c("span", { staticClass: "stock-status__icon" }),
            _vm._v(" "),
            _c("span", { staticClass: "stock-status__label" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "product__name" }, [
        _c("a", {
          attrs: { href: _vm.product.url },
          domProps: { innerHTML: _vm._s(_vm.product.title) },
        }),
      ]),
    ]),
    _vm._v(" "),
    !_vm.product.raisinsPrice
      ? _c("div", { staticClass: "price" }, [
          _vm.product.wholesale
            ? _c("div", { staticClass: "price__wholesale" }, [
                _vm._v(
                  _vm._s(_vm.product.priceWh) +
                    " руб. - от " +
                    _vm._s(_vm.product.priceWhMin) +
                    " шт"
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "price__current" }, [
            _vm._v(_vm._s(_vm.product.price) + " руб."),
          ]),
          _vm._v(" "),
          _vm.product.discount
            ? _c("span", { staticClass: "price__old" }, [
                _vm._v(_vm._s(_vm.product.priceOld) + " руб."),
              ])
            : _vm._e(),
        ])
      : _c("div", { staticClass: "price" }, [
          _c("span", { staticClass: "price__current" }, [
            _vm._v(_vm._s(_vm.product.price) + " "),
            _c("span", { staticClass: "price__raisins-icon" }),
          ]),
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "product__bottom" }, [
      _c(
        "div",
        { staticClass: "product__quantity" },
        [
          _c("Quantity", {
            attrs: { itemId: _vm.product.id },
            on: { change: _vm.catalogQuantityChange },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "product__buy" }, [
        _c(
          "a",
          {
            staticClass: "btn gt-buy_button",
            class: { active: _vm.isInCart },
            on: {
              click: function ($event) {
                return _vm.catalogUpdateCart(_vm.product.id)
              },
            },
          },
          [
            _vm._v(
              "\n\t\t\t\t\t" +
                _vm._s(_vm.isInCart ? "В корзине" : "В корзину") +
                "\n\t\t\t\t"
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }