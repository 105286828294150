<template>
  <form method="post" :action="checkout" @submit.prevent="send">
    <div v-if="message">
      <div class="alert alert-danger" role="alert">
        <span class="a-minor">{{ message }}</span>
      </div>
      <br>
    </div>
    <div class="order-section" v-if="!data.registered">
      <div class="order-section-header">
        <div class="order-section-header__l">Зарегистрироваться?</div>
        <div class="order-section-header__r"></div>
      </div>
      <div class="order-section-content">
        <br>
        <p>Вы наконец добрались до оформления заказа и отправки его нам для дальнейшей обработки. Для этого достаточно заполнить поля на форме ниже и нажать кнопку "Оформить заказ".</p>
        <p>Вы можете это сделать без регистрации на нашем сайте, но и вам и нам будет удобнее если вы все-таки зарегистрируетесь. Вы сможете увидеть статус обработки вашего заказа, да и при следующем заказе не нужно будет вводить большую часть данных.</p>
        <p>
          <a class="btn btn-primary btn-sm" :href="register"><i class="fa fa-edit"></i> Зарегистрироваться</a>
          <a class="btn btn-primary btn-sm" :href="login"><i class="fa fa-sign-in"></i> Войти</a>
        </p>
      </div>
    </div>

    <div class="order-section order-contacts">
      <div class="order-section-header">
        <div class="order-section-header__l">Контакты и согласование заказа</div>
        <div class="order-section-header__r"></div>
      </div>
      <div class="order-section-content">
        <div class="row" v-if="!data.registered">
          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <input type="text" required class="form-control" name="contact[first_name]"
                     v-model="contact.first_name"
                     :disabled="loading"
                     placeholder="Имя">
            </div>
          </div>

          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <input type="text" required class="form-control" name="contact[last_name]"
                     v-model="contact.last_name"
                     :disabled="loading"
                     placeholder="Фамилия">
            </div>
          </div>

          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <input type="email" required class="form-control" name="contact[email]"
                     v-model="contact.email"
                     :disabled="loading"
                     placeholder="Адрес электронной почты">
            </div>
          </div>

          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <input type="tel" required class="form-control" name="contact[phone]"
                     v-model="contact.phone"
                     :disabled="loading"
                     placeholder="Контактный телефон">
            </div>
          </div>
        </div>
        <div v-else>
          <div class="row">
            <div class="form-group col-md-10">
              <p><strong>Имя</strong> {{ data['contact']['first_name'] }}</p>
              <p><strong>Фамилия</strong> {{ data['contact']['last_name'] }}</p>
              <p><strong>Адрес электронной почты</strong> {{ data['contact']['email'] || 'не указан' }}</p>
              <p><strong>Контактный телефон</strong> {{ data['contact']['phone'] || 'не указан' }}</p>
            </div>
          </div>
          <div v-if="!data['contact']['first_name'] || !data['contact']['last_name'] || !data['contact']['email'] || !data['contact']['phone']">
            <div class="alert alert-danger" role="alert">
              <span class="a-minor">
                  Для успешного оформления заказа просим Вас заполнить недостающие контактные данные перейдя по <a :href="customer">ссылке <i class="fa fa-arrow-circle-right fa-lg" aria-hidden="true"></i></a>
              </span>
            </div>
            <br>
          </div>
        </div>

        <div class="row">
          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label class="radio-button-with-icon">
                <input type="radio" required name="pmethod" value="C" v-model="form.pmethod">
                <span class="radio-button-with-icon__content">
                    <span class="radio-button-with-icon__icon">
                        <img src="/img/icons/payment-cash.png" alt="">
                    </span>
                    <span class="radio-button-with-icon__text">Оплата наличными при получении</span>
                </span>
              </label>
            </div>
          </div>

          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <label class="radio-button-with-icon">
                <input type="radio" required name="pmethod" value="W" v-model="form.pmethod" :disabled="loading">
                <span class="radio-button-with-icon__content">
                    <span class="radio-button-with-icon__icon">
                        <img src="/img/icons/payment-cashless.png" alt="">
                    </span>
                    <span class="radio-button-with-icon__text">Оплата по безналичному расчету</span>
                </span>
              </label>
            </div>
          </div>
        </div>

        <div class="checkboxes">
          <div class="checkbox">
            <label class="checkbox__label">
              <input type="checkbox" name="opt_email" v-model="form.opt_email" :disabled="loading">
              <span class="checkbox__content">
                  <span class="checkbox__icon"></span>
                  <span class="checkbox__text">Отправить счет по электронной почте</span>
              </span>
            </label>
          </div>

          <div class="checkbox">
            <label class="checkbox__label">
              <input type="checkbox" name="opt_fax" v-model="form.opt_fax" :disabled="loading">
              <span class="checkbox__content">
                  <span class="checkbox__icon"></span>
                  <span class="checkbox__text">Отправить счет по факсу</span>
              </span>
            </label>
          </div>

          <div class="checkbox">
            <label class="checkbox__label">
              <input type="checkbox" v-model="hascomment" :disabled="loading">
              <span class="checkbox__content">
                  <span class="checkbox__icon"></span>
                  <span class="checkbox__text">Дополнительные пожелания</span>
              </span>
            </label>
          </div>
        </div>
        <div class="form-group" v-if="hascomment">
          <textarea class="form-control" rows="3" name="comment" placeholder="Дополнительные пожелания"
                    :disabled="loading"
                    v-model="form.comment" />
        </div>
      </div>
    </div>

    <div class="order-section order-delivery">
      <div class="order-section-header">
        <div class="order-section-header__l">Адрес доставки</div>
        <div class="order-section-header__r"></div>
      </div>
      <div class="order-section-content">
        <div v-if="data['addresses'].length > 0" class="row">
          <div class="col col-12">
            <div class="form-group" data-bind="visible: $parent.addresses().length > 1">
              <select class="form-control" name="address[id]" v-model="address.id" :disabled="loading" @change="setAddress">
                <option value="">Новый адрес доставки</option>
                <option v-for="item in data['addresses']" :value="item['id']">
                  {{
                    ['place', 'street', 'house', 'office']
                      .filter((key) => (item[key] || '').trim().length > 0)
                      .map((key) => (key === 'house' ? 'дом ' : '') + item[key])
                      .join(", ")
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <input type="text" required class="form-control" name="address[place]"
                     v-model="address.place"
                     :disabled="loading"
                     placeholder="Город или район, населенный пункт"/>
            </div>
          </div>

          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <input type="text" required class="form-control" name="address[region]"
                     v-model="address.region"
                     :disabled="loading"
                     placeholder="Регион"/>
            </div>
          </div>

          <div class="col col-12 col-md-6 col-lg-6">
            <div class="form-group">
              <input type="text" required class="form-control" name="address[street]"
                     v-model="address.street"
                     :disabled="loading"
                     placeholder="Улица"/>
            </div>
          </div>

          <div class="col col-12 col-md-6 col-lg-6">
            <div class="row">
              <div class="col col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <input type="text" class="form-control" required name="address[house]"
                         v-model="address.house"
                         :disabled="loading"
                         placeholder="Дом, корпус, строение"/>
                </div>
              </div>
              <div class="col col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <input type="text" class="form-control" name="address[office]"
                         v-model="address.office"
                         :disabled="loading"
                         placeholder="Офис, квартира"/>
                </div>
              </div>
              <div class="col col-4 col-md-4 col-lg-4">
                <div class="form-group">
                  <input type="text" inputmode="numeric" class="form-control" name="address[floor]"
                         v-model="address.floor"
                         :disabled="loading"
                         placeholder="Этаж"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-12">
            <div class="form-group">
              <input type="text" class="form-control" name="address[comment]"
                     v-model="address.comment"
                     :disabled="loading"
                     placeholder="Комментарий по доставке"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="order-section order-documents">
      <div class="order-section-header">
        <div class="order-section-header__l">Оформление документов</div>
        <div class="order-section-header__r"></div>
      </div>
      <div class="order-section-content">
        <div class="form-group">
          <select class="form-control" v-model="entity.etype" :disabled="loading" @change="setEntityType">
            <option v-for="item in entityTypes" :value="item['key']">{{ item['name'] }}</option>
          </select>
        </div>

        <div v-if="data['entities'].length > 0" class="form-group">
          <select v-if="entity.etype !== 'P'" class="form-control" name="entity[id]" v-model="entity.id" :disabled="loading" @change="setEntity">
            <option v-if="entity.etype === 'S'" value="">Новый предприниматель </option>
            <option v-if="entity.etype === 'L'" value="">Новое юридическое лицо </option>
            <option v-for="item in data['entities']" :value="item['id']" v-if="item['label'] && item['etype'] === entity.etype">
              {{ item['label'] }}
            </option>
          </select>
        </div>

        <div class="row" v-if="entity.etype !== 'P'">
          <div class="col col-12">
            <div class="form-group">
              <input type="text" class="form-control" required name="entity[label]"
                     v-model="entity.label"
                     :disabled="loading"
                     placeholder="Наименование для документов"
              >
            </div>
          </div>

          <div class="col col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <input type="text" inputmode="numeric" required
                     :minlength="entity.etype === 'L' ? 10 : 12" :maxlength="entity.etype === 'L' ? 10 : 12"
                     class="form-control"
                     name="entity[inn]"
                     v-model="entity.inn"
                     :disabled="loading"
                     placeholder="ИНН"
              >
            </div>
          </div>

          <div v-if="entity.etype === 'L'" class="col col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <input type="text" inputmode="numeric" required class="form-control" name="entity[kpp]"
                     v-model="entity.kpp"
                     :maxlength="9"
                     :disabled="loading"
                     placeholder="КПП"
              >
            </div>
          </div>

          <div :class="['col', 'col-12', 'col-md-4', entity.etype === 'L' ? 'col-lg-4' : 'col-lg-8']">
            <div class="form-group">
              <input type="text" inputmode="numeric"
                     :minlength="entity.etype === 'L' ? 13 : 15" :maxlength="entity.etype === 'L' ? 13 : 15"
                     class="form-control"
                     name="entity[ogrn]"
                     v-model="entity.ogrn"
                     :disabled="loading"
                     placeholder="ОГРН"
              >
            </div>
          </div>

          <div class="col col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <input type="text" inputmode="numeric" minlength="9" maxlength="9" class="form-control"
                     name="entity[accbid]"
                     v-model="entity.accbid"
                     :disabled="loading"
                     placeholder="БИК"
              >
            </div>
          </div>

          <div class="col col-12 col-md-8 col-lg-8">
            <div class="form-group">
              <input type="text" inputmode="numeric" minlength="20" maxlength="20" class="form-control"
                     name="entity[accnum]"
                     v-model="entity.accnum"
                     :disabled="loading"
                     placeholder="Расчётный счет"
              >
            </div>
          </div>

          <div class="col col-12">
            <div class="form-group">
              <input type="text" class="form-control" name="entity[legaddr]"
                     v-model="entity.legaddr"
                     :disabled="loading"
                     :placeholder="entity.etype === 'L' ? 'Юридический адрес' : 'Адрес регистрации'"
              >
            </div>
          </div>
          <div class="col col-12 checkboxes">
            <div v-if="entity.etype === 'L'" class="checkbox">
              <label class="checkbox__label">
                <input type="checkbox" v-model="entityHasactaddr" :disabled="loading">
                <span class="checkbox__content">
                  <span class="checkbox__icon"></span>
                  <span class="checkbox__text">Фактический адрес отличается от юридического</span>
                </span>
              </label>
            </div>
          </div>
          <div class="col col-12">
            <div v-if="entity.etype === 'L' && entityHasactaddr" class="form-group">
              <input type="text" class="form-control" name="entity[actaddr]"
                     v-model="entity.actaddr"
                     :disabled="loading"
                     placeholder="Фактический регистрации"
              >
            </div>
          </div>
          <div class="col col-12 checkboxes">
            <div class="checkbox">
              <label class="checkbox__label">
                <input type="checkbox" v-model="diffconsignee" :disabled="loading">
                <span class="checkbox__content">
                  <span class="checkbox__icon"></span>
                  <span class="checkbox__text">Грузополучатель отличается от текущего</span>
                 </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="entity.etype !== 'P' && diffconsignee" class="order-section order-documents">
      <div class="order-section-header">
        <div class="order-section-header__l">Грузополучатель</div>
        <div class="order-section-header__r"></div>
      </div>
      <div class="order-section-content">
        <div class="form-group">
          <select class="form-control" v-model="consignee.etype" :disabled="loading">
            <option v-for="item in entityTypes.filter((e) => e.key !== 'P')" :value="item['key']">{{ item['name'] }}</option>
          </select>
        </div>

        <div class="row">
          <div class="col col-12">
            <div class="form-group">
              <input type="text" required class="form-control" name="consignee[label]"
                     v-model="consignee.label"
                     :disabled="loading"
                     placeholder="Наименование для документов"
              >
            </div>
          </div>

          <div class="col col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <input type="text" inputmode="numeric" required
                     :minlength="consignee.etype === 'L' ? 10 : 12" :maxlength="consignee.etype === 'L' ? 10 : 12"
                     class="form-control"
                     name="consignee[inn]"
                     v-model="consignee.inn"
                     :disabled="loading"
                     placeholder="ИНН"
              >
            </div>
          </div>

          <div v-if="consignee.etype === 'L'" class="col col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <input type="text" inputmode="numeric" required
                     :minlength="consignee.etype === 'L' ? 13 : 15" :maxlength="consignee.etype === 'L' ? 13 : 15"
                     class="form-control"
                     name="consignee[kpp]"
                     v-model="consignee.kpp"
                     :disabled="loading"
                     placeholder="КПП"
              >
            </div>
          </div>

          <div :class="['col', 'col-12', 'col-md-4', consignee.etype === 'L' ? 'col-lg-4' : 'col-lg-8']">
            <div class="form-group">
              <input type="text" inputmode="numeric" class="form-control" name="consignee[ogrn]"
                     v-model="consignee.ogrn"
                     :disabled="loading"
                     placeholder="ОГРН"
              >
            </div>
          </div>

          <div class="col col-12 col-md-4 col-lg-4">
            <div class="form-group">
              <input type="text" inputmode="numeric" minlength="9" maxlength="9" class="form-control"
                     name="consignee[accbid]"
                     v-model="consignee.accbid"
                     :disabled="loading"
                     placeholder="БИК"
              >
            </div>
          </div>

          <div class="col col-12 col-md-8 col-lg-8">
            <div class="form-group">
              <input type="text" inputmode="numeric" minlength="20" maxlength="20" class="form-control"
                     name="consignee[accnum]"
                     v-model="consignee.accnum"
                     :disabled="loading"
                     placeholder="Расчётный счет"
              >
            </div>
          </div>

          <div class="col col-12">
            <div class="form-group">
              <input type="text" class="form-control" name="consignee[legaddr]"
                     v-model="consignee.legaddr"
                     :disabled="loading"
                     :placeholder="consignee.etype === 'L' ? 'Юридический адрес' : 'Адрес регистрации'"
              >
            </div>
          </div>
          <div class="col col-12 checkboxes">
            <div v-if="consignee.etype === 'L'" class="checkbox">
              <label class="checkbox__label">
                <input type="checkbox" v-model="consigneeHasactaddr" :disabled="loading">
                <span class="checkbox__content">
                  <span class="checkbox__icon"></span>
                  <span class="checkbox__text">Фактический адрес отличается от юридического</span>
                </span>
              </label>
            </div>
          </div>
          <div class="col col-12">
            <div v-if="consignee.etype === 'L' && consigneeHasactaddr" class="form-group">
              <input type="text" class="form-control" name="consignee[actaddr]"
                     v-model="consignee.actaddr"
                     :disabled="loading"
                     placeholder="Фактический регистрации"
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="supervisors.length > 0" class="order-section order-contacts">
      <div class="order-section-header">
        <div class="order-section-header__l">Модераторы</div>
        <div class="order-section-header__r"></div>
      </div>
      <div class="order-section-content">
        <br>
        <p>
          В соответствии с настройками вашего аккаунта, ваш заказ должен
          быть одобрен одним из указанных пользователей.
          Выберите, к кому из них вы хотите отправить заказ на проверку.
        </p>
        <div class="row">
          <div class="col col-12">
            <div v-for="s in supervisors" class="form-group">
              <label class="control-label">
                <input type="radio" name="moderator" v-model="moderator" :value="s.id" :disabled="loading">
                {{ s.label }}
              </label>
            </div>
          </div>
          <div class="col col-12 checkboxes">
            <div class="checkbox">
              <label class="checkbox__label">
                <input type="checkbox" v-model="hascommenttomoder" :disabled="loading">
                <span class="checkbox__content">
                  <span class="checkbox__icon"></span>
                  <span class="checkbox__text">Комментарий модератору</span>
                </span>
              </label>
            </div>
          </div>
          <div v-if="hascommenttomoder" class="col col-12">
            <div class="form-group">
              <input class="form-control" v-model="form.comment_to_moder" :disabled="loading" placeholder="Комментарий модератору">
            </div>
          </div>
        </div>
      </div>
    </div>
    <p>
      <button type="submit" class="btn btn-primary gt-order_button" :disabled="loading">Отправить заказ</button>
    </p>
  </form>
</template>
<script>
import axios from "axios"

export default {
  props: {
    data: { type: Object, required: true },
    supervisors: { type: Array, required: true },
    checkout: { type: String, required: true },
    register: { type: String, required: true },
    login: { type: String, required: true },
    customer: { type: String, required: true },
  },
  data() {
    return {
      loading: true,
      message: "",
      entityTypes: [
        { key: "L", name: "Юридическое лицо: ООО, ОАО, АО, ГУП, ФГУП и т.п." },
        { key: "S", name: "Индивидуальный предприниматель: ИП, адвокат, нотариус" },
        { key: "P", name: "Частное лицо: документы на контактное лицо" },
      ],
      hascomment: false,
      entityHasactaddr: false,
      consigneeHasactaddr: false,
      diffconsignee: false,
      hascommenttomoder: false,
      form: {
        pmethod: "",
        opt_email: false,
        opt_fax: false,
        comment: "",
        comment_to_moder: "",
      },
      contact: {
        id: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
      },
      address: {
        id: "",
        place: "",
        region: "",
        street: "",
        house: "",
        office: "",
        floor: "",
        comment: "",
      },
      entity: {
        id: "",
        etype: "P",
        label: "",
        inn: "",
        kpp: "",
        ogrn: "",
        accbid: "",
        accnum: "",
        legaddr: "",
        actaddr: "",
      },
      consignee: {
        id: "",
        etype: "L",
        label: "",
        inn: "",
        kpp: "",
        ogrn: "",
        accbid: "",
        accnum: "",
        legaddr: "",
        actaddr: "",
      },
      moderator: "",
    }
  },
  mounted() {
    const contact = this.data.contact || {}
    const address = this.data.address || {}
    const entity = this.data.entity || {}
    const consignee = this.data.consignee || {}

    this.hascomment = !!this.data.comment
    this.entityHasactaddr = !!this.entity.actaddr
    this.consigneeHasactaddr = !!this.consignee.actaddr
    this.diffconsignee = !!this.consignee.id
    for (const key in this.form) {
      this.form[key] = this.data[key] || ""
    }
    for (const key of ["opt_fax", "opt_email"]) {
      this.form[key] = this.data[key] || false
    }
    for (const key in this.contact) {
      this.contact[key] = contact[key] || ""
    }
    for (const key in this.address) {
      this.address[key] = address[key] || ""
    }
    for (const key in this.entity) {
      this.entity[key] = entity[key] || ""
    }
    this.entity.etype = this.entity.etype === "" ? "L" : this.entity.etype
    for (const key in this.consignee) {
      this.consignee[key] = consignee[key] || ""
    }
    this.consignee.etype = this.consignee.etype === "" ? "L" : this.consignee.etype

    this.loading = false
  },
  methods: {
    async send() {
      this.loading = true

      const form = {
        "!send": true,
        ...this.form,
        contact: {...this.contact},
        address: {...this.address},
        entity: {...this.entity},
        moderator: this.moderator,
      }

      if (!this.hascomment) {
        form.comment = ""
      }
      if (!this.hascommenttomoder) {
        form.comment_to_moder = ""
      }

      var erase = []
      if (this.entity.etype === "P") {
        erase = [...erase, "label", "inn", "kpp", "ogrn", "accbid", "accnum", "legaddr", "actaddr"]
      }
      if (this.entity.etype === "S") {
        erase = [...erase, "kpp", "actaddr"]
      }
      if (!this.entityHasactaddr) {
        erase = [...erase, "actaddr"]
      }
      for (const key of erase) {
        form.entity[key] = ""
      }

      if (this.diffconsignee) {
        form.consignee = {...this.consignee}
        erase = this.consignee.etype === "S" ? ["kpp", "actaddr"] : []
        erase = this.consigneeHasactaddr ? erase : [...erase, "actaddr"]
        for (const key of erase) {
          form.consignee[key] = ""
        }
      }
      try {
        const response = await axios.post(this.checkout, form)
        if (response.data["msg"]) {
          this.message = response.data["msg"]
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
        if (response.data["!location"]) {
          window.location = response.data["!location"]
        }
      } catch (e) {}
      this.loading = false
    },
    setAddress() {
      const address = this.data["addresses"].find((a) => a["id"] === this.address.id) || {}
      for (const key in this.address) {
        this.address[key] = address[key] || ""
      }
    },
    setEntityType() {
      this.entity.id = ''
    },
    setEntity() {
      const entity = this.data["entities"].find((ent) => ent["id"] === this.entity.id) || {}
      for (const key in this.entity) {
        if (key === "etype")
          continue
        this.entity[key] = entity[key] || ""
      }
    }
  },
}
</script>

<script setup>
</script>
