<template>
	<div class="product">
		<div class="product__photo">
			<img :src="product.img" v-on:click="openModal(product.modal_img)"/>
			<span
				class="product__favorite icon-favorite"
				v-on:click="updateFavoriteStatus(product.id)"
				:class="{'active': isFavorite}"
				v-if="product.customer_user"
			></span>
			<span class="product__text-label" v-if="product.product_of_the_day">Товар дня</span>
			<div
				v-if="product.discount"
				class="product__discount"
				:style="'background-color:' + product.color"
			>-{{ product.discountValue }}</div>
		</div>
		<div class="product__description">
		<div class="product__code-line"><span class="product__sku">{{ product.sku }}</span>

			<span :class="'product__stock-status stock-status ss-' + product.stock_status">
        <span class="stock-status__icon"></span>
        <span class="stock-status__label"></span>
			</span>
		</div>
		<div class="product__name">
			<a :href="product.url" v-html="product.title"></a>
		</div>
		<!-- <div class="product__rating">
			<div class="rating"><span class="rating__star"></span><span class="rating__star"></span><span class="rating__star"></span><span class="rating__star"></span><span class="rating__star"></span>
			</div><span class="product__rating-total">0</span>
		</div> -->
		</div>
		<div class="price" v-if="!product.raisinsPrice">
			<div class="price__wholesale" v-if="product.wholesale">{{ product.priceWh }} руб. - от {{ product.priceWhMin }} шт</div>
			<span class="price__current">{{ product.price }} руб.</span>
			<span class="price__old" v-if="product.discount">{{ product.priceOld }} руб.</span>
		</div>
		<div class="price" v-else>
			<span class="price__current">{{ product.price }} <span class="price__raisins-icon"></span></span>
		</div>
		<div class="product__bottom">
		<div class="product__quantity">
			<Quantity :itemId="product.id" @change="catalogQuantityChange"/>
		</div>
			<div class="product__buy">
				<a class="btn gt-buy_button" :class="{'active': isInCart}" @click="catalogUpdateCart(product.id)">
					{{ isInCart ? 'В корзине' : 'В корзину' }}
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import Quantity from './widgets/quantity/Quantity.vue';

export default {
	data: function() {
		return {
			isFavorite: false,
			isInCart: false,
		}
	},
	props: {
		product: {
			type: Object
		}
	},
	mounted: function() {
		this.isFavorite = this.product.is_favorite;
		this.isInCart = this.product.is_in_cart;
	},
	created() {
        this.$root.$on('itemRemoved', this.itemRemoved);
    },
	methods: {
		catalogQuantityChange(event) {
			const app = Yarok.getApp();
			app.catalogQuantityChange(event);
		},
		async catalogUpdateCart(itemId) {
			const app = Yarok.getApp();
			await app.catalogUpdateCart(itemId);
			this.isInCart = app.isInCart(itemId);
		},
		updateFavoriteStatus(itemId) {
			const app = Yarok.getApp();
			app.toggleProductFavorite(itemId);
			this.isFavorite = !app.isFavorite(itemId);
		},
		openModal(image) {
			const app = Yarok.getApp();
			app.openModal(image);
		},
		itemRemoved() {
			const app = Yarok.getApp();
			this.isInCart = app.isInCart(this.product.id); 
		},

	},
	components: {
		Quantity
	}

}
</script>

<style lang="sass" src="./product.sass"></style>
